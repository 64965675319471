import * as S from "./LandingMoneyTransfer.style";
import LandingButton from "../LandingButton/LandingButton";
import {
  LandingGreySec,
  LandingGreySecWrap,
} from "../../shared-styled-components";
import { useMoneyTransferAnim } from "../../hooks/useMoneyTransferAnim";
import { Image } from "../Image";

const LandingMoneyTransfer = ({ title, list, imgs }: any) => {
  useMoneyTransferAnim();
  return (
    <LandingGreySec padding="3rem 0 5rem 0">
      <LandingGreySecWrap>
        <S.LandingMoneyTransfer id="moneyTransferMobile_ref">
          <div>
            <S.LandingMoneyTransferTitle>{title}</S.LandingMoneyTransferTitle>
            {list && (
              <S.LandingMoneyTransferList>
                <li>{list[0]}</li>
                <li>{list[1]}</li>
              </S.LandingMoneyTransferList>
            )}
            {/* <LandingButton customTitle="TRANSFER NOW" />
             */}
            <div>Coming soon</div>
          </div>
          <S.LandingMoneyTransferImgWrap>
            <div>
              <S.GreyBoxSmall></S.GreyBoxSmall>
              <S.GreyBoxSmall></S.GreyBoxSmall>
            </div>
            <S.GreyBoxBig></S.GreyBoxBig>
          </S.LandingMoneyTransferImgWrap>
          <Image id="brownArrMobile_ref" className="brownArr" {...imgs?.[0]} />
          <Image id="blueArrMobile_ref" className="blueArr" {...imgs?.[1]} />
          <Image
            id="moneyTrans1Mobile_ref"
            className="moneyTrans1"
            {...imgs?.[2]}
          />
          <Image
            id="moneyTrans2Mobile_ref"
            className="moneyTrans2"
            {...imgs?.[3]}
          />
          <Image
            id="moneyTrans3Mobile_ref"
            className="moneyTrans3"
            {...imgs?.[4]}
          />
          <Image
            id="moneyTrans4Mobile_ref"
            className="moneyTrans4"
            {...imgs?.[5]}
          />
        </S.LandingMoneyTransfer>
      </LandingGreySecWrap>
    </LandingGreySec>
  );
};

export default LandingMoneyTransfer;
