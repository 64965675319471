import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const useMoneyTransferAnim = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let timeId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const moneyTransElement = document.getElementById(
        "moneyTransferMobile_ref"
      );
      const brownArrElement = document.getElementById("brownArrMobile_ref");
      const blueArrElement = document.getElementById("blueArrMobile_ref");
      const moneyTrans1Element = document.getElementById(
        "moneyTrans1Mobile_ref"
      );
      const moneyTrans2Element = document.getElementById(
        "moneyTrans2Mobile_ref"
      );
      const moneyTrans3Element = document.getElementById(
        "moneyTrans3Mobile_ref"
      );
      const moneyTrans4Element = document.getElementById(
        "moneyTrans4Mobile_ref"
      );
      gsap.to(brownArrElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        x: -43,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(blueArrElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        x: 42,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(moneyTrans1Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        scale: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(moneyTrans2Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        scale: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(moneyTrans3Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        scale: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(moneyTrans4Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: moneyTransElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        scale: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
    }, 300);
    return () => clearTimeout(timeId);
  }, []);
};
