import styled from "styled-components";

export const LandingMoneyTransfer = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 16px 16px 16px 42px;
  border-radius: 16px;
  position: relative;

  & .brownArr {
    width: 100px;
    height: 50px;
    position: absolute;
    right: 100px;
    top: 185px;
    z-index: 1;
  }

  & .blueArr {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 126px;
    right: 186px;
    z-index: 1;
  }

  & .moneyTrans1 {
    width: 84px;
    height: 105px;
    position: absolute;
    right: 227px;
    top: 77px;
    transform: scale(0.8);
  }
  & .moneyTrans2 {
    width: 48px;
    height: 80px;
    position: absolute;
    right: 91px;
    top: 115px;
    transform: scale(0.8);
  }
  & .moneyTrans3 {
    width: 48px;
    height: 59px;
    position: absolute;
    right: 195px;
    bottom: 28px;
    transform: scale(0.8);
  }
  & .moneyTrans4 {
    width: 33px;
    height: 51px;
    position: absolute;
    right: 112px;
    bottom: 31px;
    transform: scale(0.8);
  }
`;

export const LandingMoneyTransferTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #000000;
  margin-bottom: 24px;
`;

export const LandingMoneyTransferImgWrap = styled.div`
  margin-left: 84px;
  display: flex;
`;

export const GreyBoxSmall = styled.div`
  width: 158px;
  height: 133px;
  background: #fafafa;
  border-radius: 20px;
  flex-shrink: 0;

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const GreyBoxBig = styled.div`
  background: #fafafa;
  border-radius: 20px;
  width: 172px;
  height: 273px;
  margin-left: 12px;
  flex-shrink: 0;
`;

export const LandingMoneyTransferList = styled.ul`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding-left: 18px;
  gap: 66px;
  margin-bottom: 24px;
  display: flex;

  & li {
    position: relative;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 8px;
    background: #000000;
    border-radius: 14px;
  }
`;
